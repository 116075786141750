/* eslint-disable prefer-rest-params */
import axios from 'axios';
import qs from 'qs';

export const MAESTRO_GA_ID = 'UA-66886055-3';

export const postAction = async event => (
  axios({
    data: qs.stringify(event),
    method: 'POST',
    url: 'https://insights.maestro.io/action',
  })
);

// for debugging add google analytics debugger and make sure no
// other iframes are sending out GA calls
export const loadGoogleAnalytics = (customGAId) => {
  // Initialize the dataLayer
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  // Initialize the gtag function with the current timestamp
  gtag('js', new Date());

  gtag('config', MAESTRO_GA_ID);

  // Load the customer-provided GA ID if available
  if (customGAId) {
    gtag('config', customGAId);
  }

  // Dynamically load the gtag.js script
  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    const js = d.createElement(s); js.id = id;
    js.async = true;
    js.src = `https://www.googletagmanager.com/gtag/js?id=${MAESTRO_GA_ID}`;
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'google_gtag_script_id'));
};

